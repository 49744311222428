// React
import React from 'react';
import PropTypes from 'prop-types';

// Stylesheets
import './style.sass';

// Utils
import { getS3File } from 'utils/s3Upload';

class Image extends React.Component {
  render() {
    const { uri, style, className, ...props } = this.props;
    const local = uri.includes('blob:') || uri.includes('static') || uri.includes('imgur');
    const _style = { backgroundImage: `url(${local ? uri : getS3File(uri)})` };

    return (
      <div
        style={{ ..._style, ...style }}
        className={`image ${className}`}
        {...props}
      >
        {this.props.children}
      </div>
    );
  }
}

Image.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  uri: PropTypes.string.isRequired,
  cache: PropTypes.number,
};

export default Image;
