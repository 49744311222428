import S3 from 'aws-sdk/clients/s3';
import queries from 'apollo/queries';
import client from '../apollo';
import uuid from 'uuid';
import axios from 'axios';
import constants from 'config/constants';
const {IS_DUOC_DESIGN} = constants;

const acceptedVideoExtensions = {
  mp4: 'video',
  avi: 'video',
  flv: 'video',
  mov: 'video',
  quicktime: 'video',
};

const acceptedAudioExtensions = {
  mp3: 'audio',
  wav: 'audio',
  ogg: 'audio',
  m4a: 'audio',
  webm: 'audio',
};

const acceptedImageExtensions = {
  png: 'image',
  jpg: 'image',
  jpeg: 'image',
  bmp: 'image',
  gif: 'image',
};

const acceptedDocumentExtensions = {
  pdf: 'doc',
  docx: 'doc',
  pptx: 'doc',
  xslx: 'doc',
};

const getFileType = (extension, accepted) => {
  return accepted[extension.toLowerCase()];
};

const uploadProfilePicture = (
  file,
  userId,
  extension,
  onUploadPercentage = () => {},
  manageUpload = () => {}
) => {
  const fileType = getFileType(extension, acceptedImageExtensions);

  if (!fileType) {
    return new Promise((_, rej) => rej('Unsupported file type'));
  }
  const newName = uuid.v4();
  const link = `uploads/profile_images/${userId}/${newName}.${extension}`;
  return uploadFile(file, link, onUploadPercentage, manageUpload);
};

const uploadImageUniversity = (
  file,
  userId,
  extension,
  onUploadPercentage = () => {},
  manageUpload = () => {}
) => {
  const fileType = getFileType(extension, acceptedImageExtensions);
  if (!fileType) {
    return new Promise((_, rej) => rej('Unsupported file type'));
  }
  const newName = uuid.v4();
  const link = `uploads/logo/${userId}/${newName}.${extension}`;
  return uploadFile(file, link, onUploadPercentage, manageUpload);
};

const uploadImageEstablishment = (
  file,
  userId,
  extension,
  onUploadPercentage = () => {},
  manageUpload = () => {}
) => {
  const fileType = getFileType(extension, acceptedImageExtensions);
  if (!fileType) {
    return new Promise((_, rej) => rej('Unsupported file type'));
  }
  const newName = uuid.v4();
  const link = `uploads/establishment_image/${userId}/${newName}.${extension}`;
  return uploadFile(file, link, onUploadPercentage, manageUpload);
};

const uploadForumImage = (
  file,
  courseId,
  extension,
  onUploadPercentage = () => {},
  manageUpload = () => {}
) => {
  const fileType = getFileType(extension, acceptedImageExtensions);
  if (!fileType) {
    return new Promise((_, rej) => rej('Unsupported file type'));
  }
  const newName = uuid.v4();
  const link = `uploads/forum/${courseId}/${newName}.${extension}`;
  return uploadFile(file, link, onUploadPercentage, manageUpload);
};

const uploadCoursePicture = (
  file,
  courseId,
  extension,
  onUploadPercentage = () => {},
  manageUpload = () => {}
) => {
  const fileType = getFileType(extension, acceptedImageExtensions);
  if (!fileType) {
    return new Promise((_, rej) => rej('Unsupported file type'));
  }
  const newName = uuid.v4();
  const link = `uploads/course_preview/${courseId}/${newName}.${extension}`;
  return uploadFile(file, link, onUploadPercentage, manageUpload);
};

const uploadFileToCertificates = (
  file,
  course_id,
  extension,
  onUploadPercentage = () => {},
  manageUpload = () => {}
) => {
  const acceptedExtensions = {
    ...acceptedDocumentExtensions,
  };
  const fileType = getFileType(extension, acceptedExtensions);
  if (!fileType) {
    return new Promise((_, rej) => rej('Unsupported file type'));
  }
  const newName = uuid.v4();
  const link = `uploads/certificates/${course_id}/${fileType}/${newName}.${extension}`;
  return uploadFile(file, link, onUploadPercentage, manageUpload);
};

const uploadFileToFeedback = (
  file,
  feedbackId,
  extension,
  onUploadPercentage = () => {},
  manageUpload = () => {}
) => {
  const acceptedExtensions = {
    ...acceptedAudioExtensions,
    ...acceptedImageExtensions,
  };
  const fileType = getFileType(extension, acceptedExtensions);
  if (!fileType) {
    return new Promise((_, rej) => rej('Unsupported file type'));
  }
  const newName = uuid.v4();
  const link = `uploads/feedbacks/${feedbackId}/${fileType}/${newName}.${extension}`;
  return uploadFile(file, link, onUploadPercentage, manageUpload);
};

const uploadFileToProfile = (
  file,
  userId,
  extension,
  onUploadPercentage = () => {},
  manageUpload = () => {}
) => {
  const acceptedExtensions = {
    ...acceptedDocumentExtensions,
  };
  const fileType = getFileType(extension, acceptedExtensions) || 'doc';
  const newName = uuid.v4();
  const lowerExtension = extension.toLowerCase();
  const ext = `.${lowerExtension}`;
  const link = `uploads/profile_docs/${userId}/${fileType}/${newName}${ext}`;

  return uploadFile(file, link, onUploadPercentage, manageUpload);
};

// MAYBEE
const uploadFileToStage = (
  file,
  stageId,
  extension,
  onUploadPercentage = () => {},
  manageUpload = () => {}
) => {
  const acceptedExtensions = {
    ...acceptedVideoExtensions,
    ...acceptedAudioExtensions,
    ...acceptedImageExtensions,
    ...acceptedDocumentExtensions,
  };

  const fileType = getFileType(extension, acceptedExtensions) || 'doc';
  const newName = uuid.v4();
  const lowerExtension = extension.toLowerCase();
  const ext = lowerExtension === 'mov' ? '' : `.${lowerExtension}`;
  const link = `uploads/stage_files/${stageId}/${fileType}/${newName}${ext}`;
  return uploadFile(file, link, onUploadPercentage, manageUpload, fileType);
};

// MAYBEE
const uploadFileTutorialVideo = (
  file,
  info,
  extension,
  onUploadPercentage = () => {},
  manageUpload = () => {}
) => {
  const acceptedExtensions = {
    ...acceptedVideoExtensions,
    ...acceptedAudioExtensions,
    ...acceptedImageExtensions,
    ...acceptedDocumentExtensions,
  };
  const fileType = getFileType(extension, acceptedExtensions) || 'doc';
  const newName = uuid.v4();
  const lowerExtension = extension.toLowerCase();
  const ext = lowerExtension === 'mov' ? '' : `.${lowerExtension}`;
  const link = `uploads/tutorials_app/${fileType}/${newName}${ext}`;

  return uploadFile(file, link, onUploadPercentage, manageUpload);
};

// MAYBEE
const uploadAnswer = (
  file,
  { courseId, stageId },
  extension,
  onUploadPercentage = () => {},
  manageUpload = () => {}
) => {
  const fileType = getFileType(extension, acceptedVideoExtensions);
  if (!fileType) {
    return new Promise((_, rej) => rej('Unsupported file type'));
  }
  const newName = uuid.v4();
  const lowerExtension = extension.toLowerCase();
  const ext = lowerExtension === 'mov' ? '' : `.${lowerExtension}`;
  const link = `uploads/course/${courseId}/stages/${stageId}/answers/${newName}${ext}`;
  return uploadFile(file, link, onUploadPercentage, manageUpload);
};

// MAYBEE
const uploadCommonMistake = (
  file,
  courseId,
  extension,
  onUploadPercentage = () => {},
  manageUpload = () => {}
) => {
  const fileType = getFileType(extension, acceptedVideoExtensions);
  if (!fileType) {
    return new Promise((_, rej) => rej('Unsupported file type'));
  }
  const newName = uuid.v4();
  const lowerExtension = extension.toLowerCase();
  const ext = lowerExtension === 'mov' ? '' : `.${lowerExtension}`;
  const link = `uploads/common_mistakes/${courseId}/${fileType}/${newName}${ext}`;
  return uploadFile(file, link, onUploadPercentage, manageUpload);
};

// MAYBEE
const uploadPresentationVideo = (
  file,
  courseId,
  extension,
  onUploadPercentage = () => {},
  manageUpload = () => {}
) => {
  const fileType = getFileType(extension, acceptedVideoExtensions);
  if (!fileType) {
    return new Promise((_, rej) => rej('Unsupported file type'));
  }
  const newName = uuid.v4();
  const lowerExtension = extension.toLowerCase();
  const ext = lowerExtension === 'mov' ? '' : `.${lowerExtension}`;
  const link = `uploads/presentation_videos/${courseId}/${fileType}/${newName}${ext}`;
  return uploadFile(file, link, onUploadPercentage, manageUpload);
};

// MAYBEE
const uploadPresentationVideoUniversity = (
  file,
  universityId,
  extension,
  onUploadPercentage = () => {},
  manageUpload = () => {}
) => {
  const fileType = getFileType(extension, acceptedVideoExtensions);
  if (!fileType) {
    return new Promise((_, rej) => rej('Unsupported file type'));
  }
  const newName = uuid.v4();
  const lowerExtension = extension.toLowerCase();
  const ext = lowerExtension === 'mov' ? '' : `.${lowerExtension}`;
  const link = `uploads/presentation_videos/university/${universityId}/${fileType}/${newName}${ext}`;
  return uploadFile(file, link, onUploadPercentage, manageUpload);
};

const convertVideo = async (s3Location) => {
  const { data } = await client.mutate({
    mutation: queries.createConvertJob,
    variables: { s3Location },
  });
  console.log('Convert video result: ', data);
  return data;
};

const transcodeVideo = async (s3Location) => {
  const { data } = await client.mutate({
    mutation: queries.createTranscodeJob,
    variables: { s3Location },
  });
  console.log('Transcode video result: ', data);
  return data;
};

const getS3File = (string) => {
  const oldUrl = process.env.REACT_APP_OLD_ASSETS_URL;
  const staticUrl = process.env.REACT_APP_ALTERNATIVE_ASSETS_URL;
  const newUrl = process.env.REACT_APP_ASSETS_URL;
  const newBucketURl = process.env.REACT_APP_NEW_ASSETS_URL;
  if (string.includes(oldUrl)) return string.replace(oldUrl, newUrl);
  if (
    string.includes(staticUrl) ||
    string.includes('data:image') ||
    string.includes('blob:')
  )
    return string;
  if (string.includes('http')) return string;
  if (string.includes('new-folder/')) return `https://${newBucketURl}/${string}`;
  return `https://${newUrl}/${string}`;
};

const s3FileAccessible = async (url) => {
  const finalUrl = getS3File(url) + `?${Date.now()}`;
  try {
    await axios.head(finalUrl);
    return true;
  } catch (err) {
    return false;
  }
};

// MAYBEE
const uploadFile = async (
  file,
  link,
  onUploadPercentage = () => {},
  manageUpload = () => {},
  contentType = false
) => {
  const {
    data: { generateS3AccessCredentials: credentials },
  } = await client.query({
    query: queries.generateAwsKeys,
    fetchPolicy: 'no-cache',
  });
  const s3 = new S3({
    accessKeyId: credentials.access_key_id,
    secretAccessKey: credentials.secret_access_key,
    sessionToken: credentials.session_token,
    region: process.env.REACT_APP_AWS_DEFAULT_REGION,
    httpOptions: {
      timeout: 360000,
    },
  });

  // let newFile;
  // let percentage = 0;
  // if (file.type.includes('video')) {
  //   newFile = await transcode({
  //     file,
  //     onProgress: ({ ratio }) => {
  //       percentage = ((Math.round((ratio * 100)) / 2));
  //       onUploadPercentage(percentage);
  //     },
  //   });
  // }
  // newFile = newFile || file;
  // console.log(newFile);
  // percentage = 50;
  let bucket_name = process.env.REACT_APP_S3_ASSETS_BUCKET;
  
  if (IS_DUOC_DESIGN) {
    link = 'new-folder/' + link;
    bucket_name = process.env.REACT_APP_S3_NEW_ASSETS_BUCKET
  }

  const params = {
    Key: link, // link.includes('.mp4') ? link : `${link}.mp4`,
    Body: file,
    ACL: 'public-read',
    Bucket: bucket_name,
  };
  if (file.type.includes('video')) {
    params['StorageClass'] = 'STANDARD_IA';
  }

  if (contentType) {
    params['ContentType'] = file.type;
    if (!link.toLowerCase().includes('html5.html'))
      params['ContentDisposition'] = 'attachment';
    else 
      params['CacheControl'] = null;
  }

  return new Promise((resolve, reject) => {
    const uploadManager = s3
      .upload(params)
      .on('httpUploadProgress', function ({ loaded, total }) {
        onUploadPercentage(Math.round((loaded * 100) / total));
      });
    uploadManager.send(function (err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
    manageUpload(uploadManager);
  });
};

const uploadS3Folder = async (
  files,
  course_id,
  stages_id,
  porcentage = () => {},
  index
) => {
  const newName = uuid.v4();
  let countUploads = 0;
  let isError = false;
  let pageIndex = '';

  // for each file in the directory
  for (const file of files) {
    // get the full path of the file
    const link =
      'uploads/articulate/' +
      course_id +
      '/' +
      stages_id +
      '/' +
      newName +
      '/' +
      file.webkitRelativePath;

    if (link.toLowerCase().includes('html5.html')) {
      pageIndex = link;
    }
    try {
      await uploadFile(
        file,
        link,
        () => {},
        () => {},
        true
      );

      countUploads += 1;
      porcentage(index, ((countUploads / files.length) * 100).toFixed(2));
      if (countUploads === files.length && !isError) return pageIndex;
    } catch (e) {
      isError = true;
      return null;
    }
  }
};
const uploadFileToPayment = (
  file,
  courseId,
  extension,
  onUploadPercentage = () => {},
  manageUpload = () => {}
) => {
  const acceptedExtensions = {
    ...acceptedImageExtensions,
    ...acceptedDocumentExtensions,
  };
  const fileType = getFileType(extension, acceptedExtensions);
  if (!fileType) {
    return new Promise((_, rej) => rej('Unsupported file type'));
  }
  const newName = uuid.v4();
  const link = `uploads/payments/course/${courseId}/${fileType}/${newName}.${extension}`;
  return uploadFile(file, link, onUploadPercentage, manageUpload);
};

const getSizeByURL = async (key) => {
  const {
    data: { generateS3AccessCredentials: credentials },
  } = await client.query({
    query: queries.generateAwsKeys,
    fetchPolicy: 'no-cache',
  });
  const s3 = new S3({
    accessKeyId: credentials.access_key_id,
    secretAccessKey: credentials.secret_access_key,
    sessionToken: credentials.session_token,
    region: process.env.REACT_APP_AWS_DEFAULT_REGION,
    httpOptions: {
      timeout: 360000,
    },
  });

  let bucket_name = process.env.REACT_APP_S3_ASSETS_BUCKET;

  if (IS_DUOC_DESIGN) {
    bucket_name = process.env.REACT_APP_S3_NEW_ASSETS_BUCKET
  }

  const params = {
    Bucket: bucket_name,
    Key: key,
  };
  return s3ObjectCallback(s3, params);
};

const s3ObjectCallback = async (s3, params) => {
  return new Promise((resolve, reject) => {
    s3.getObject(params, async (err, data) => {
      if (err || !data) return reject(err);

      const size = parseFloat((data.ContentLength / 1048576).toFixed(2));

      return resolve(size);
    });
  });
};

const getS3Metadata = async (link) => {
  const {
    data: { generateS3AccessCredentials: credentials },
  } = await client.query({
    query: queries.generateAwsKeys,
    fetchPolicy: 'no-cache',
  });
  const s3 = new S3({
    accessKeyId: credentials.access_key_id,
    secretAccessKey: credentials.secret_access_key,
    sessionToken: credentials.session_token,
    region: process.env.REACT_APP_AWS_DEFAULT_REGION,
    httpOptions: {
      timeout: 360000,
    },
  });
  let bucket_name = process.env.REACT_APP_S3_ASSETS_BUCKET;
  if (IS_DUOC_DESIGN) {
    bucket_name = process.env.REACT_APP_S3_NEW_ASSETS_BUCKET
  }

  const params = {
    Bucket: bucket_name,
    Key: link,
  };
  return new Promise((resolve, reject) => {
    s3.getObject(params, async (err, data) => {
      if (err || !data) return reject(err);

      const objectMetadata = data;

      return resolve(objectMetadata);
    });
  });
};

const getSizeAndDurationByVideoFile = (videoFile) => {
  return new Promise((resolve) => {
    const video_size_without_compress = parseFloat(
      (videoFile.size / 1048576).toFixed(2)
    );
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = (_) => {
      window.URL.revokeObjectURL(video.src);
      const video_duration = video.duration;
      resolve({ video_duration, video_size_without_compress });
    };

    video.onerror = (_) =>
      resolve({ video_duration: 0, video_size_without_compress });
    video.src = URL.createObjectURL(videoFile);
  });
};

const getDurationByURL = (videoURL) => {
  return new Promise((resolve) => {
    const link = getS3File(videoURL);
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = (_) => {
      window.URL.revokeObjectURL(link);
      const video_duration = video.duration;
      resolve({ video_duration });
    };
    video.src = link;
  });
};

export {
  uploadFileToStage,
  getS3File,
  uploadProfilePicture,
  uploadImageEstablishment,
  uploadFileToCertificates,
  uploadImageUniversity,
  uploadForumImage,
  s3FileAccessible,
  convertVideo,
  transcodeVideo,
  uploadAnswer,
  uploadCommonMistake,
  uploadCoursePicture,
  uploadFileToFeedback,
  uploadPresentationVideo,
  uploadS3Folder,
  uploadFileTutorialVideo,
  uploadFileToPayment,
  uploadFileToProfile,
  uploadPresentationVideoUniversity,
  getSizeByURL,
  getSizeAndDurationByVideoFile,
  getDurationByURL,
  getS3Metadata,
};
