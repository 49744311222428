import React, { useEffect, useState } from 'react';
import AOS from 'aos';

import client from 'apollo';
import history from './utils/history';
import queries from './apollo/queries';
import { Link } from 'react-router-dom';
import Image from "components/Image";
import 'aos/dist/aos.css';

// Router
import Router from './router';
import ServerUnavailable from './components/Errors/ServerUnavailable';

// Intenationalization
import I18nextProviderC from './config/i18n';

import ScrollToTop from 'components/ScrollToTop';

// Style
import './style.sass';

import GA from './utils/GoogleAnalytics';
import ipapi from  'ipapi.co';

AOS.init({ duration: 2000, offset: 300, delay: 1000 });

const getLocation = () => {
  const locale = localStorage.getItem('locale');
  if (!locale){
    ipapi.location((data) => {
      if(data) {
        const languages = data.languages;
        const mainLanguage = languages.split(',');

        if (mainLanguage.length > 0 && mainLanguage[0].includes('es-')){
          localStorage.setItem('locale', 'es');
        }else {
          localStorage.setItem('locale', 'en');
          window.location.reload();
        }
      }
    });
  }
}

function App() {
  const [health, setHealth] = useState(true);
  const [openAlert, setOpenAlert] = useState(true);
  
  getLocation();
  
  useEffect(() => {
    checkForMobile();
  }, []);

  useEffect(() => {
    serverHealthCheck();
  }, []);


  const checkForMobile = () => {
    if (
      /android/i.test(navigator.userAgent) ||
      (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream)
    ) {
      const pathname = window.location.pathname;
      if (
        pathname.includes('auth') ||
        pathname.includes('course/') ||
        (pathname.includes('courses') &&
          pathname.includes('stages') &&
          !pathname.includes('feedback')) ||
        (pathname.includes('courses') &&
          pathname.includes('stages') &&
          pathname.includes('feedback'))
      ) {
        window.open(`c1do1:/${pathname}`);
      }
    }
  };

  const serverHealthCheck = async () => {
    try {
      const {
        data: { health: health },
      } = await client.query({
        query: queries.checkHealth,
        fetchPolicy: 'network-only',
      });
      setHealth(health);
    } catch (e) {
      console.log(e);
      setHealth(false);
    }
  };

   const alert = () => {

      return (
        <div class="alert-container" id="alert">
          <div class="alert-box">
          
            <div class="alert-header">
              <div class="alert-icon">!</div>
              <div class="alert-title">AVISO IMPORTANTE</div>
            </div>
            
          
            <p class="alert-message">
            Nuestro canal de soporte por Whatsapp ya no está disponible y podría estar siendo utilizado por terceros. Por favor, eviten responder o interactuar con ese número.
            <br/>
            <br/>
            Para soporte, contáctenos a través del formulario de contacto o al correo gabriele@c1do1.ai
            <br/>
            <br/>
            Gracias por su comprensión.
            </p>
            
            <div class="alert-logo">
              <Image
                uri={require("assets/images/c1do1-logo-new.svg")}
                className="logo-c1"
              />
            </div>
          
            
            <div class="alert-buttons">
              <button class="btn close-btn" onClick={()=> setOpenAlert(false) }>CERRAR</button>
              <Link to='/contact'><button class="btn form-btn"  onClick={()=> setOpenAlert(false)} >IR AL FORMULARIO</button></Link>
            </div>
          </div>
        </div>
      );
    } 
  return (
    <div id='app'>
      {GA.init() && <GA.RouteTracker />}
      <I18nextProviderC>
        { health ?
        <>
        
          <ScrollToTop />
          <Router history={history} />
        </>
          :
          <ServerUnavailable />
        }
      </I18nextProviderC> 
        

    </div>
  );
}

export default App;
